<template>
  <div>
    <h2> สินค้า</h2>
    <v-row>
      <v-col
        cols="12"
        sm="6"
        md="6"
      >
        <v-card style="min-height: 180px;">
          <v-card-text>
            <br>
            <h3
              class="text-center"
            >
              สรุปมูลค่าสินค้าทั้งหมด
            </h3>
            <br>
            <h1 class="text-center">
              {{ total }} บาท
            </h1>
            <v-btn
              class="text-center"
              @click="download_excel(1)"
            >
              Download Excel
            </v-btn>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col
        cols="12"
        sm="6"
        md="6"
      >
        <v-card style="min-height: 180px;">
          <v-card-text>
            <h3
              class="text-center"
            >
              มูลค่าสินค้าคงเหลือรายคลัง
            </h3>

            <Pie
              :chart-options="chartOptions"
              :chart-data="chartData"
              :chart-id="chartId"
              :dataset-id-key="datasetIdKey"
              :plugins="plugins"
              :css-classes="cssClasses"
              :styles="styles"
              :width="width"
              :height="height"
              :options="options"
            />
          </v-card-text>
        </v-card>
      </v-col>
      <v-col
        cols="12"
        sm="12"
        md="12"
      >
        <!--        <v-select-->
        <!--          v-model="typeGraphSelected"-->
        <!--          outlined-->
        <!--          dense-->
        <!--          hide-details-->
        <!--          :items="typeGraph1"-->
        <!--          item-text="name"-->
        <!--          item-value="value"-->
        <!--          class="selected"-->
        <!--        ></v-select>-->

        <v-data-table
          :headers="header1"
          :items="reportTable1"
          :search="search"
          :server-items-length="total1"
          :options.sync="serverOptions"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          class="elevation-1"
        >
          <template v-slot:top>
            <v-toolbar flat>
              <!-- <v-toolbar-title> -->
              <v-row>
                <v-col cols="4">
                  <v-text-field
                    v-model="search"
                    label="Search"
                    single-line
                    hide-details
                  ></v-text-field>
                </v-col>
                <v-col cols="4">
                  <v-select
                    v-model="typeGraphSelected"
                    outlined
                    dense
                    hide-details
                    label="กรุณาเลือก"
                    :items="titleGrapht1"
                    item-text="name"
                    item-value="value"
                    class="selected"
                  ></v-select>
                </v-col>
                <v-col cols="4">
                  <v-btn
                    class="text-center"
                    @click="download_excel(2)"
                  >
                    Download Excel
                  </v-btn>
                </v-col>
                <!-- </v-toolbar-title> -->
                <!-- <v-divider class="mx-4" inset vertical></v-divider> -->
                <v-spacer></v-spacer>
              </v-row>
            </v-toolbar>
          </template>
          <template v-slot:item.index="{ index }">
            {{ ((serverOptions.page - 1) * serverOptions.itemsPerPage) + index + 1 }}
          </template>
          <template v-slot:item.value="{ item }">
            {{ item.product_costs * item.product_amount }}
          </template>
          <template v-slot:no-data>
            <!-- <v-btn
              color="primary"
              @click="initialize"
            >
              Reset
            </v-btn> -->
          </template>
        </v-data-table>
      </v-col>
      <!--      <v-col-->
      <!--        cols="12"-->
      <!--        sm="12"-->
      <!--        md="12"-->
      <!--      >-->
      <!--        <v-data-table-->
      <!--          :headers="header2"-->
      <!--          :items="reportTable2"-->
      <!--          :search="search2"-->
      <!--          :server-items-length="total2"-->
      <!--          :options.sync="serverOptions2"-->
      <!--          :sort-by.sync="sortBy2"-->
      <!--          :sort-desc.sync="sortDesc2"-->
      <!--          class="elevation-1"-->
      <!--        >-->
      <!--          <template v-slot:top>-->
      <!--            <v-toolbar flat>-->
      <!--              &lt;!&ndash; <v-toolbar-title> &ndash;&gt;-->
      <!--              <v-text-field-->
      <!--                v-model="search2"-->
      <!--                label="Search"-->
      <!--                single-line-->
      <!--                hide-details-->
      <!--              ></v-text-field>-->
      <!--              &lt;!&ndash; </v-toolbar-title> &ndash;&gt;-->
      <!--              &lt;!&ndash; <v-divider class="mx-4" inset vertical></v-divider> &ndash;&gt;-->
      <!--              <v-spacer></v-spacer>-->
      <!--            </v-toolbar>-->
      <!--          </template>-->
      <!--          <template v-slot:item.index="{ index }">-->
      <!--            {{ ((serverOptions2.page - 1) * serverOptions2.itemsPerPage) + index + 1 }}-->
      <!--          </template>-->
      <!--          <template v-slot:no-data>-->
      <!--            &lt;!&ndash; <v-btn-->
      <!--              color="primary"-->
      <!--              @click="initialize"-->
      <!--            >-->
      <!--              Reset-->
      <!--            </v-btn> &ndash;&gt;-->
      <!--          </template>-->
      <!--        </v-data-table>-->
      <!--      </v-col>-->
    </v-row>
  </div>
</template>

<script>
import { mdiPencil, mdiDelete } from '@mdi/js'
import Swal from 'sweetalert2'

import { Bar, Pie, Line as LineChartGenerator } from 'vue-chartjs/legacy'
import {
  Chart as ChartJS, ArcElement, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale,
  PointElement,
  LineElement,
} from 'chart.js'
import instance_report from '@/services/report'
import instance_product from '@/services/product'

ChartJS.register(Title, ArcElement, Tooltip, Legend, BarElement, CategoryScale, LinearScale, PointElement, LineElement)

// import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale } from 'chart.js'
//
// ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale)

export default {
  name: 'ValidName',
  components: {
    Swal, Bar, Pie, LineChartGenerator,
  },
  props: {
    chartId: {
      type: String,
      default: 'bar-chart',
    },
    datasetIdKey: {
      type: String,
      default: 'label',
    },
    width: {
      type: Number,
      default: 400,
    },
    height: {
      type: Number,
      default: 400,
    },
    cssClasses: {
      default: '',
      type: String,
    },
    styles: {
      type: Object,
      default: () => {
      },
    },
    plugins: {
      type: Array,
      default: () => [],
    },
  },
  setup() {
    return {
      icons: {
        mdiPencil,
        mdiDelete,
      },
    }
  },
  data: () => ({
    typeGraphSelected: 1,
    testData: {
      labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
      datasets: [
        {
          label: 'Data One',
          backgroundColor: '#f87979',
          data: [40, 39, 10, 40, 39, 80, 40],
        },
      ],
    },
    options: {
      responsive: true,
      maintainAspectRatio: false,
    },
    configs: [],
    dialog: false,
    dialogDelete: false,
    page1: 1,
    page2: 1,
    size: 10,
    search: '',
    search2: '',
    titleGrapht1: [
      {
        value: 1,
        name: 'สินค้าขายไม่ได้มากกว่า 7วัน',
      },
      {
        value: 2,
        name: 'สินค้าขายไม่ได้มากกว่า 1 เดือน',
      },
      {
        value: 3,
        name: 'สินค้าขายไม่ได้มากกว่า 3เดือน',
      },
      {
        value: 4,
        name: 'สินค้าขายไม่ได้มากกว่า 6เดือน',
      },
      {
        value: 5,
        name: 'สินค้าขายไม่ได้มากกว่า 1 ปี',
      },
    ],
    chartData: {
      labels: ['ลูกค้าใหม่', 'ลูกค้าซื้อซ้ำ', 'test'],
      datasets: [
        {
          label: 'ลูกค้าใหม่',
          backgroundColor: ['#f87979', '#3E80E1', '#00FF00'],
          data: [40, 80, 70],
        },
      ],

    },
    chartOptions: {
      responsive: true,
    },
    serverOptions: {
      page: 1,
      itemsPerPage: 10,
    },
    serverOptions2: {
      page: 1,
      itemsPerPage: 10,
    },
    total: 0,
    total1: 0,
    total2: 0,
    reports: [],
    reportTable1: [],
    reportTable2: [],
    sortBy: '',
    sortDesc: '',
    sortBy2: '',
    sortDesc2: '',
    header1: [
      {
        text: 'ลำดับ',
        value: 'index',
        sortable: false,
      },
      { text: 'รหัสสินค้า', value: 'product_code' },
      { text: 'สินค้า', value: 'product_name' },

      // { text: 'วันที่ขายล่าสุด', value: 'product_type_name', sortable: false },
      { text: 'จำนวนคงเหลือ', value: 'product_amount', sortable: false },
      { text: 'มูลค่าสินค้าคงเหลือ (บาท)', value: 'value', sortable: false },
    ],
    header2: [
      {
        text: 'ลำดับ',
        value: 'index',
        sortable: false,
      },
      { text: 'รหัสสินค้า', value: 'product_code' },
      { text: 'สินค้า', value: 'product_name' },
      { text: 'คงเหลือ', value: 'product_amount', sortable: false },
    ],
    report: {},
    editedIndex: -1,
    date: [],
  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? 'เพิ่ม ตั้งค่าใช้จ่ายใน Order' : 'แก้ไข ตั้งค่าใช้จ่ายใน Order'
    },
  },

  watch: {
    serverOptions: {
      handler() {
        this.getReportTable1()
      },
    },
    search: {
      handler() {
        this.getReportTable1()
      },
    },
    typeGraphSelected: {
      handler() {
        this.getReportTable1()
      },
    },

    // serverOptions2: {
    //   handler() {
    //     this.getReportTable2()
    //   },
    // },
    // search2: {
    //   handler() {
    //     this.getReportTable2()
    //   },
    // },
  },

  created() {
    // this.initialize()
  },
  mounted() {
    // const date = new Date()
    // const day = date.getDate()
    // const last_month = date.getMonth()
    // const month = date.getMonth() + 1
    // const year = date.getFullYear()
    //
    // // this.date = `${year}-${String(month).padStart(2, '0')}-${day}`;
    //
    // this.date.push(`${year}-${String(last_month).padStart(2, '0')}-${day}`)
    // this.date.push(`${year}-${String(month).padStart(2, '0')}-${day}`)
    this.getReport()
    this.getReportTable1()
    this.getReportTable2()
  },

  methods: {
    download_excel(type = 1) {
      if (type == 1) {
        var url = `${process.env.VUE_APP_BASEURL}/exports_total_product_csv?search=${this.search}&date=${this.date}`
      } else {
        var url = `${process.env.VUE_APP_BASEURL}/exports_table_product_csv?search=${this.search}&date=${this.date}`
      }
      window.open(url, '_blank', 'noreferrer')
    },
    deleteFilePattern() {
      // Swal.fire({
      //   title: 'Are you sure?',
      //   text: "You won't be able to revert this!",
      //   icon: 'warning',
      //   showCancelButton: true,
      //   confirmButtonColor: '#3085d6',
      //   cancelButtonColor: '#d33',
      //   confirmButtonText: 'Yes, delete it!'
      // }).then((result) => {
      //   if (result.isConfirmed) {
      //     Swal.fire(
      //       'Deleted!',
      //       'Your file has been deleted.',
      //       'success'
      //     )
      //   }
      // })
      // return false;
      Swal.fire({
        title: 'คุณแน่ใจต้องการลบไฟล์สั่งเพิ่มเติม !',
        text: 'ลบไฟล์สั่งเพิ่มเติม หลัง 30 วัน ',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'ยืนยัน',
        cancelButtonText: 'ยกเลิก',
      })
        .then(willDelete => {
          // let loader = this.$loading.show({
          //   loader: 'dots',
          //   color: '#27ae60'
          // });
          if (willDelete.isConfirmed) {
            instance_pattern.deleteFile().then(res => {
              Swal.fire({
                icon: 'success',
                title: 'ลบไฟล์สั่งเพิ่มเติม หลัง 30 วัน สำเสร็จ',
                showConfirmButton: false,
                timer: 1500,
              })
            }).catch(err => {
              Swal.fire({
                icon: 'error',
                title: err.message,
                showConfirmButton: false,
                timer: 1500,
              })
            })

            //   axios.get('{{url('admin/deleteFilePattern')}}')
            // .then(response => {
            //     loader.hide()
            //
            //   })
            //     .catch(error => {
            //       loader.hide()
            //       this.swalAlert(error.message, 'error')
            //       console.log(error.message);
            //     });
          } else {
            Swal.fire('แน่ใจเเล้วสามารถยืนยันการ ลบไฟล์สั่งเพิ่มเติมหลัง 30 วัน ได้ทันที')

            //   swal("แน่ใจเเล้วสามารถยืนยันการ ลบไฟล์สั่งเพิ่มเติมหลัง 30 วัน ได้ทันที");
            //
          }
        })
    },
    editItem(item) {
      this.editedIndex = this.configs.indexOf(item)
      this.editedItem = { ...item }
      this.dialog = true
    },
    close() {
      this.dialog = false
      this.$nextTick(() => {
        this.editedItem = { ...this.defaultItem }
        this.editedIndex = -1
      })
    },

    closeDelete() {
      this.dialogDelete = false
      this.$nextTick(() => {
        this.editedItem = { ...this.defaultItem }
        this.editedIndex = -1
      })
    },
    getReport() {
      this.$store.state.isLoading = true
      const data = {
        search: this.search,
        type: '',
        sortBy: this.sortBy ? this.sortBy : '',
        sortDesc: this.sortDesc ?? '',
        size: this.serverOptions.itemsPerPage,
        page: this.serverOptions.page,
      }

      instance_product.getReportProduct(data).then(res => {
        // this.report = res.data.data.data
        this.total = res.data.data.total

        const lbs = []
        const vals = []

        for (const key in res.data.data.product_type_value) {
          lbs.push(key)
          vals.push(res.data.data.product_type_value[key])
        }
        this.chartData.labels = lbs
        this.chartData.datasets[0].data = vals

        this.$store.state.isLoading = false
      }).catch(err => {
        this.$store.state.isLoading = false
      })
    },
    getReportTable1() {
      this.$store.state.isLoading = true
      const data = {
        search: this.search ? this.search : '',
        date: '',
        status: '',
        type: this.typeGraphSelected,
        sortBy: this.sortBy ? this.sortBy : '',
        sortDesc: this.sortDesc ?? '',
        size: this.serverOptions.itemsPerPage,
        page: this.serverOptions.page,
      }

      instance_product.getReportProductOutOfStock(data).then(res => {
        this.reportTable1 = res.data.data.data
        this.total1 = res.data.data.total
        this.serverOptions.itemsPerPage = this.serverOptions.itemsPerPage == -1 ? -1 : res.data.data.per_page
        this.serverOptions.page = res.data.data.current_page
        this.$store.state.isLoading = false
      }).catch(err => {
        this.$store.state.isLoading = false
      })
    },
    getReportTable2() {
      this.$store.state.isLoading = true
      const data = {
        search: this.search2 ? this.search2 : '',
        date: '',
        status: '',
        type: this.typeGraphSelected,
        sortBy: this.sortBy2 ? this.sortBy2 : '',
        sortDesc: this.sortDesc2 ?? '',
        size: this.serverOptions.itemsPerPage,
        page: this.serverOptions.page,
      }

      instance_product.getReportProductLeft(data).then(res => {
        this.reportTable2 = res.data.data.data
        this.total2 = res.data.data.total
        this.serverOptions2.itemsPerPage = this.serverOptions.itemsPerPage == -1 ? -1 : res.data.data.per_page
        this.serverOptions2.page = res.data.data.current_page
        this.$store.state.isLoading = false
      }).catch(err => {
        this.$store.state.isLoading = false
      })
    },
    getOutOfStock() {
      this.$store.state.isLoading = true
      const data = {
        search: '',
        type: '',
        sortBy: '',
        sortDesc: '',
        size: '',
        page: '',
      }

      instance_product.getReportProduct(data).then(res => {
        this.report = res.data.data.data
        this.total2 = res.data.data.total
        this.$store.state.isLoading = false
      }).catch(err => {
        this.$store.state.isLoading = false
      })
    },
    getProductLeft() {
      this.$store.state.isLoading = true
      const data = {
        search: '',
        date: this.date,
      }

      instance_product.getReportProduct(data).then(res => {
        this.report = res.data.data.data
        this.$store.state.isLoading = false
      }).catch(err => {
        this.$store.state.isLoading = false
      })
    },
    openLink(item) {
      // date=2023-05-24&date=2023-06-24
      // 2023/05/24%20-%202023/06/24
      this.$router.push({
        name: item.to.name,
        query: { search: item.search, status: item.status_th, date: this.date.toString() },
      })

      // router.push(})
    },
  },
}
</script>

<style scoped>
hr {
  background-color: #e5e5e5;
  height: 1px;
  border: none;
}
</style>
